import IDomScript from '@/modules/i-dom-script';

export default class DatePicker implements IDomScript {
    private $el: any;
    private $input: any;
    private $icon: any;
    private toggleButton: any;
    private label: any;
    private minDate: any;
    private maxDate: any;
    private dataOptions: any;
    private optionsSet: any;
    private urlFormat: any;
    private picker: any;
    private selectedDate: any;
    private options: any;

    public constructor(el: HTMLElement) {
        this.init(el);
    }

    private async init(el: HTMLElement): Promise<void> {
        const { default: $ } = await import(/* webpackChunkName: "jquery" */ 'jquery');
        window.jQuery = $;

        await import(/* webpackChunkName: "picker" */ 'picker');
        await import(/* webpackChunkName: "pickadate" */ 'pickadate');

        $.extend(($.fn as any).pickadate.defaults, {
            monthsFull: [
                'Januari',
                'Februari',
                'Mars',
                'April',
                'Maj',
                'Juni',
                'Juli',
                'Augusti',
                'September',
                'Oktober',
                'November',
                'December'
            ],
            weekdaysShort: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
            today: 'IDAG',
            firstDay: true
        });

        this.$el = $(el);
        this.$input = this.$el.find('input');
        this.$icon = this.$el.find('i');
        this.label = this.$input.data('val');
        this.toggleButton = this.$el.find("[aria-label='Öppna datumväljare']").end();
        this.minDate = this.$el.data('min-date');
        this.maxDate = this.$el.data('max-date');
        this.dataOptions = this.$el.data('options');

        this.optionsSet = false;

        this.urlFormat = decodeURI(this.$el.data('url-format'));

        this.$input.pickadate({
            selectYears: 100,
            selectMonths: true,
            today: this.dataOptions ? false : 'IDAG',

            klass: {
                box: 'box',
                buttonClear: 'clear',
                buttonToday: 'today',
                day: 'date-picker-day',
                disabled: 'disabled',
                frame: 'frame',
                header: 'header',
                highlighted: 'highlighted',
                holder: 'date-picker',
                navNext: 'dp-nav-next',
                navPrev: 'dp-nav-prev',
                navDisabled: 'nav-disabled',
                now: 'now',
                opened: 'opened',
                outfocus: 'outfocus',
                selectMonth: 'select-month',
                selectYear: 'select-year',
                table: 'table',
                weekdays: 'date-picker-weekday',
                wrap: 'wrap'
            }
        });

        this.selectedDate = this.$el.data('selected-date');
        this.picker = this.$input.pickadate('picker');
        this.picker.set('select', this.selectedDate.split('-'));
        this.picker.set('min', this.minDate.split('-'));
        this.picker.set('max', this.maxDate.split('-'));

        this.$input.val(this.label);

        this.picker.on({
            set: (e: any) => {
                const selectedDate = this.picker.get('select', 'yyyy-mm-dd'),
                    url = this.urlFormat.replace('{date}', selectedDate);

                this.$input.val(this.label);

                if (e.select === undefined) {
                    return;
                }

                document.location = url;
            },
            close: () => {
                this.$icon.toggleClass('calendar');
                this.$icon.toggleClass('calendar-active');
                this.toggleButton.attr('aria-expanded', 'false');
            },
            open: () => {
                this.toggleButton.attr('aria-expanded', 'true');
                if (this.dataOptions && !this.optionsSet) {
                    this.options = this.getDateArray(this.dataOptions);
                    this.optionsSet = true;
                    this.picker.set('disable', this.options);
                    this.picker.set('enable', 'flip');
                }
            }
        });

        this.$input.on('click', function (e: any) {
            e.preventDefault();
        });

        this.$el.on('click', (e: any) => {
            this.picker.open();
            e.stopPropagation();
        });
    }

    getDateArray(datesString: any) {
        const arr = datesString.split(', ');
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].split('-');
        }
        return arr;
    }

    public execute(): void {}

    public dispose(): void {
        this.picker.stop();
        this.$input.off('click');
        this.$el.off('click');
    }
}
