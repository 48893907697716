import spaPageRender from '@/spa-navigation/spa-page-render';
import spaPageTracker from '@/spa-navigation/spa-page-tracker';
import spaNavigationApi from '@/api/spa-navigation-api';
import { HistoryState } from '@/spa-navigation/history-handler';
import { isVersionMismatch } from '@/spa-navigation/version-handler';
import spaTeaserFlowRender from '@/spa-navigation/spa-teaser-flow-render';
import { throttledErrorLogger } from '@/logging/ThrottledErrorLogger';
import { nextTick } from '@/common/dom-helpers';
import { urlState } from './url-state';
import linkEvaluator from '@/common/link-evaluator';
import { DomUpdatedEvent } from '@/common/custom-events/dom-updated-event';
import { PageChangedEvent } from '@/common/custom-events/page-changed-event';
import { SpaNavigationStartedEvent } from '@/common/custom-events/spa-navigation-started-event';
import { PageRenderedEvent } from '@/common/custom-events/page-rendered-event';

export async function popstateEventListener(event: PopStateEvent): Promise<void> {
    try {
        const historyState: HistoryState | null = event.state;
        const poppedUrl = window.location.href;

        if (linkEvaluator.isSameRelativeUrl(urlState.getUrl(), window.location.href)) {
            urlState.saveUrl(window.location.href);
            return;
        }

        window.dispatchEvent(new SpaNavigationStartedEvent());

        const res = await spaNavigationApi.getHtml(window.location.href);

        if (newPopstateEventTriggered(poppedUrl)) {
            return;
        }

        const ajaxTeaserFlowUrl = spaTeaserFlowRender.tryGetAjaxTeaserFlowUrl(res.html);

        if (isVersionMismatch(res.html)) {
            hardReload();
            return;
        }

        spaPageRender.renderPage(res.html);
        window.dispatchEvent(new PageRenderedEvent(res.html));

        urlState.saveUrl(window.location.href);

        if (ajaxTeaserFlowUrl) {
            const flowResponse = await spaNavigationApi.getHtml(ajaxTeaserFlowUrl);

            if (newPopstateEventTriggered(poppedUrl)) {
                return;
            }

            spaTeaserFlowRender.appendAjaxTeaserFlowToDom(flowResponse.html);
        }

        if (historyState?.teaserFlow) {
            spaTeaserFlowRender.appendStoredTeaserFlowToDom(historyState.teaserFlow);
        }

        const scrollPosition = historyState?.scroll ?? 0;
        nextTick(() => window.scroll(0, scrollPosition));

        window.dispatchEvent(new DomUpdatedEvent());
        window.dispatchEvent(new PageChangedEvent(res.html));
        spaPageTracker.track();
    } catch (error) {
        throttledErrorLogger.logError({ message: 'Spa-navigation popstate failed', error: error });
        hardReload();
    }
}

function newPopstateEventTriggered(poppedUrl: string): boolean {
    return poppedUrl !== window.location.href;
}

function hardReload(): void {
    window.location.reload();
}
